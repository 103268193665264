<template>
  <div>
    <a-card :title="$t('上架单详情')">
      <a-button slot="extra" type="primary" ghost icon="left" @click="$router.go(-1)">{{ $t("返回") }}</a-button>

      <a-spin :spinning="orderLoading">
        <img class="jsbarcode" id="jsbarcode" ref="jsbarcode" />
        <a-descriptions bordered>
          <a-descriptions-item :label="$t('上架单号')">
            {{ shelveOrderItem.number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('入库通知单号')">
            {{ shelveOrderItem.receipt_order_item?.number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('仓库')">
            {{ shelveOrderItem.warehouse_item?.name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('货主')">
            {{ shelveOrderItem.client_item?.name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('应上架数量')">
            {{ shelveOrderItem.total_quantity }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('剩余数量')">
            {{ shelveOrderItem.remain_quantity }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('状态')">
            {{ shelveOrderItem.is_void ? $t("已作废") : shelveOrderItem.is_completed ? $t("已完成") : $t("进行中") }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('创建人')">
            {{ shelveOrderItem.creator_item?.name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('创建时间')">
            {{ shelveOrderItem.create_time }}
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>

      <div style="margin-top: 16px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="shelveMaterialItems"
          :loading="materialLoading"
          :pagination="false"
        >
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { shelveOrderRetrieve, shelveOrderMaterials } from "@/api/stockIn";
import JsBarcode from "jsbarcode";
import NP from "number-precision";

export default {
  data() {
    return {
      NP,
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: this.$t("产品编号"),
          dataIndex: "material_number",
          customRender: (_value, item) => item.material_item.number,
        },
        {
          title: this.$t("产品名称"),
          dataIndex: "material_name",
          customRender: (_value, item) => item.material_item.name,
        },
        {
          title: this.$t("批次编号"),
          dataIndex: "material_name",
          customRender: (_value, item) => item.batch_item.number,
        },
        {
          title: this.$t("应上架数量"),
          dataIndex: "total_quantity",
        },
        {
          title: this.$t("剩余数量"),
          dataIndex: "remain_quantity",
        },
        {
          title: this.$t("推荐库区"),
          dataIndex: "recommend_areas",
          customRender: (_value, item) => item.recommend_areas.join(", "),
        },
      ],
      orderLoading: false,
      materialLoading: false,
      shelveOrder: undefined,
      shelveOrderItem: {},
      shelveMaterialItems: [],
    };
  },
  methods: {
    initData() {
      document.getElementById("jsbarcode").html = "";
      this.shelveOrder = this.$route.query.id;

      this.orderLoading = true;
      shelveOrderRetrieve({ id: this.shelveOrder })
        .then((data) => {
          console.log(data);
          this.shelveOrderItem = data;
          new JsBarcode(this.$refs.jsbarcode, data.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
        })
        .finally(() => {
          this.orderLoading = false;
        });

      this.materialLoading = true;
      shelveOrderMaterials({ id: this.shelveOrder })
        .then((data) => {
          this.shelveMaterialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style>
.jsbarcode {
  float: right;
}
</style>
